import banner from "./photo_banner.jpeg";
import "./App.css";
import { useEffect, useState } from "react";
import Particles from "react-particles";
import { loadFireworksPreset } from "tsparticles-preset-fireworks";
import tg from "./4.png";
import tw from "./2.png";

function App() {
  const [play, setIsPlay] = useState(false);

  const [count, setCount] = useState(0);

  const options = {
    preset: "fireworks",
    emitters: {
      autoPlay: play,
    },
  };

  useEffect(() => {
    if (count > 1) {
      setIsPlay(true);
    }
  }, [count]);

  return (
    <div className="App">
      <div>
        <div className="banner">
          <img src={banner} alt="" />
        </div>
        <button
          className={`btn-primary ${count > 0 ? "" : "blur"}`}
          onClick={() => {
            setCount(count + 1);
          }}
        >
          Continue
        </button>
        <p className="text">
          <span>0XXXXXXXXXX</span>
          <br />
          <span>owner@black.xyz</span>
        </p>
      </div>
      <div className="socials">
        <a
          href="https://twitter.com/BlackCoinETH"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={tw} alt="" />
        </a>
        <a
          href="https://t.me/BlackCoinETH"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={tg} alt="" />
        </a>
      </div>
      <Particles
        options={options}
        init={async (engine) => {
          await loadFireworksPreset(engine);
        }}
      />
    </div>
  );
}

export default App;
